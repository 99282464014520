import React from 'react';
import Wrapper from '../components/wrapper';
import Email from '../components/email';
import Mobile from '../components/mobile';
import Phone from '../components/phone';

export default (props) => {

    const page = props['*'];
    return (
    <Wrapper page={page}>
      <h1 className='d-block d-lg-none page'>Contact</h1>
      <div className='row'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Steve Caron</h5>
              <Phone /><br />
              <Email address="steve@wirespecialties.com" />
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <h5 className='card-title'>Valerie Caron</h5>
              <Phone /><br />
              <Email address="valerie@wirespecialties.com" />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
